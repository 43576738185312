import axios from "axios";

//const BASEURL = "https://test.service-kalibrcompany.ru/api/";
const BASEURL = "/api/";
export const HTTPGET = axios.create({
  method: "get",
  baseURL: BASEURL,
  withCredentials: true
});
export const HTTPPOSTFILE = axios.create({
  method: "post",
  baseURL: BASEURL,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data"
  }
});
export const HTTPPOST = axios.create({
  method: "post",
  baseURL: BASEURL,
  withCredentials: true
});
