<template>
  <button
    class="btn btn-outline-primary my-2 my-sm-0"
    type="submit"
    @click="logout"
  >
    выход
  </button>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Logout",
  methods: {
    ...mapActions(["LOGOUT"]),
    logout() {
      this.LOGOUT();
      this.$router.push("/login");
    }
  }
};
</script>
<style scoped>
.btn-outline-primary {
  background: white;
}
.btn-outline-primary:hover {
  color: #007bff;
}
</style>
