import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/repairs",
    name: "Repairs",
    meta: { layout: "main", auth: true, reports: true },
    component: () => import("@/views/repairs/Repairs.vue"),
    children: [
      {
        path: "/repairs/add-new-repair",
        name: "AddNewRepair",
        meta: { layout: "main", auth: true, reports: true },
        component: () => import("@/views/repairs/AddNewRepair.vue")
      },
      {
        path: "/repairs/repairs-list",
        name: "RepairsList",
        meta: { layout: "main", auth: true, reports: true },
        component: () => import("@/views/repairs/RepairsList.vue")
      },
      {
        path: "/repairs/repair-:id",
        name: "OneRepair",
        meta: { layout: "main", auth: true, reports: true },
        component: () => import("@/views/repairs/OneRepair.vue")
      }
    ]
  },
  {
    path: "/kabinet",
    name: "Kabinet",
    meta: { layout: "main", auth: true },
    component: () => import("@/views/Kabinet.vue")
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "empty" },
    component: () => import("@/views/Login.vue")
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, _from, next) => {
  const requireAuth = to.matched.some(record => record.meta.reports);
  if (!store.state.userInfo.reports && requireAuth) {
    next("/");
  } else {
    next();
  }
});

router.beforeEach((to, _from, next) => {
  store.dispatch("CHECK_AUTH");
  const requireAuth = to.matched.some(record => record.meta.auth);
  if (!store.getters.getAuth && requireAuth) {
    next("/login");
  } else {
    next();
  }
});
export default router;
