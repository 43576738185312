<template>
  <div id="app">
    <Loader v-if="this.$store.state.loader" />
    <div v-else />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
import Loader from "@/components/Loader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
    ...mapGetters(["getAuth"])
  },
  methods: {
    ...mapActions(["CHECK_AUTH"])
  },
  components: {
    MainLayout,
    EmptyLayout,
    Loader
  },
  mounted() {
    this.CHECK_AUTH();
    setTimeout(() => {
      if (!this.getAuth) {
        if (this.$route.path !== "/login") this.$router.push("/login");
      }
    }, 2000),
      setInterval(() => {
        this.CHECK_AUTH();
        setTimeout(() => {
          if (!this.getAuth) {
            if (this.$route.path !== "/login") this.$router.push("/login");
          }
        }, 2000);
      }, 3600000);
  }
};
</script>
