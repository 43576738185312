<template>
  <div>
    <nav id="navbar" class="navbar navbar-expand-lg navbar-dark bg-primary">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" @click="addActive('boss')" name="boss">
            <router-link class="nav-link" to="/">Главная</router-link>
          </li>
          <li
            v-if="REPORTS"
            class="nav-item"
            @click="addActive('reports')"
            name="reports"
          >
            <router-link class="nav-link" to="/repairs/repairs-list"
              >Ремонты</router-link
            >
          </li>
          <li v-else />
          <li class="nav-item" @click="addActive('kabinet')" name="kabinet">
            <router-link class="nav-link" to="/kabinet">{{
              USER_NAME
            }}</router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Dropdown
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </li>
        </ul>
        <Logout />
      </div>
    </nav>
    <router-view />
  </div>
</template>
<script>
import Logout from "@/components/Logout";
import { mapGetters } from "vuex";
export default {
  name: "main-layout",
  computed: {
    ...mapGetters(["REPORTS", "USER_NAME"])
  },
  components: {
    Logout
  },
  methods: {
    addActive(el) {
      let elem = document.getElementsByClassName("nav-item");
      elem = Array.from(elem);
      elem.map(item => {
        item.classList.remove("active");
      });
      document.getElementsByName(el)[0].classList.add("active");
    }
  }
};
</script>
<style scoped>
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: white;
}
</style>
