<template>
  <div class="loader_parent">
    <div class="spinner_wrap">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader"
};
</script>
<style scoped>
.loader_parent {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
  opacity: 0.5;
}
</style>
